import * as React from "react"
import Layout from "@/components/Layout"
import Seo from "@/components/seo"
import PageHeading from "@/components/PageHeading"

import LeadingByExample from "@/components/OurProcess/leading-by-example"
import VisionToShipping from "@/components/OurProcess/vision-to-shipping"
import AgileBuzzword from "@/components/OurProcess/agile-buzzword"
import AreasOfExpertise from "@/components/OurProcess/areas-of-expertise"
import WorkFlow from "@/components/OurProcess/work-flow"
import CallToActionMini from "@/components/CallToActionMini"

const OurProcessPage = () => {
  return (
    <Layout>
      <Seo title="Our Process" />
      <div data-aos="fade-up">
        <PageHeading
          title="Our Process"
          description="Defining, Building, and Scaling Industry-Shaping Solutions"
        />
      </div>
      <LeadingByExample />
      <VisionToShipping />
      {/* <AgileBuzzword /> */}
      <AreasOfExpertise />
      <WorkFlow />
      <CallToActionMini
        redirectTo="/hire-us"
        title="Hire Us"
        description="Ready to learn more about how we can help your business succeed in the cloud? Contact us today to schedule a consultation and start the process of achieving your goals."
      ></CallToActionMini>
    </Layout>
  )
}

export default OurProcessPage
