import * as React from "react"
import ILine from "@/components/ILine"
import DiscoverDefineImg from "../../images/our-process/Discover-define.svg"
import DesignBuildImg from "../../images/our-process/Design-build.svg"
import LaunchScaleImg from "../../images/our-process/Launch-scale.svg"
import IHeading from "../IHeading"
import IParagraph from "../IParagraph"

const VisionToShipping = () => {
  const data = [
    {
      imgSrc: DiscoverDefineImg,
      title: "Explore & Plan: Cloud Services",
      content:
        "Collaborating to uncover ideas and opportunities, we dive deep into the problem at hand. No vision is too ambitious for prototyping, and validated ideas ensure we're on the right track.",
    },
    {
      imgSrc: DesignBuildImg,
      title: "Craft & Create: Cloud Solutions",
      content:
        "Combining delightful user experiences with precise engineering, we craft technology that's both reliable and easy to use. By continuously optimizing and improving based on real user feedback, we deliver rock-solid cloud solutions.",
    },
    {
      imgSrc: LaunchScaleImg,
      title: "Go Live & Grow: Cloud Deployment",
      content:
        "Ensuring your cloud solution is a success requires making the right decisions. Our data-driven approach helps us draw insights, refine the product, and choose the right distribution and promotion strategy to achieve product-market fit.",
    },
  ]

  return (
    <section className="mt-24">
      <div className="container mx-auto">
        <div className="mb-2" data-aos="fade-up">
          <IHeading size="subTitle">
            Bringing Ideas to Life: The Development Process
          </IHeading>
        </div>
        <div data-aos="fade-up">
          <IParagraph>
            Fostering open communication and collaboration, our process is
            rooted in mutual understanding and continuous feedback between
            clients and production teams.
          </IParagraph>
        </div>
        <ILine align="left" />
        <div className="flex flex-wrap justify-between mt-24 gap-x-5 gap-y-16 lg:flex-nowrap">
          {data.map((item: any, index: number) => {
            return (
              <div
                className="flex flex-col items-center justify-center w-full md:justify-start md:items-start md:w-auto"
                key={index}
              >
                <img src={item.imgSrc} data-aos="fade-up" />
                <h3
                  className="mb-5 text-3xl md:text-4xl text-primary-700 mt-7 dark:text-primary-100"
                  data-aos="fade-up"
                >
                  {item.title}
                </h3>
                <p
                  className="mb-5 text-center w-72 md:w-64 md:text-left dark:text-primary-300"
                  data-aos="fade-up"
                >
                  {item.content}
                </p>
                <ILine align="left" />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default VisionToShipping
