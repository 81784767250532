import * as React from "react"
import ILine from "@/components/ILine"
import IHeading from "../IHeading"
import IParagraph from "../IParagraph"

const LeadingByExample = () => {
  return (
    <section className="mt-10 md:mt-20">
      <div className="container mx-auto">
        <div className="mb-2" data-aos="fade-up">
          <IHeading size="subTitle">Setting the Standard: Our Leadership Philosophy</IHeading>
        </div>
        <div data-aos="fade-up">
          <IParagraph>
            From start to finish, we guide our clients through the process of
            creating meaningful digital experiences that drive business growth
            and delight customers. Our close collaboration leads to exceptional
            results.
          </IParagraph>
        </div>
        <ILine align="left" />
        <div
          className="flex flex-col justify-center mt-20 md:flex-row"
          data-aos="fade-up"
        >
          <div className="flex flex-col md:flex-row items-center md:items-start gap-x-3.5 mt-10 z-10 mb-5 md:mb-0">
            <div className="w-64 mx-auto text-right md:w-56 md:mx-0 lg:w-64 xl:max-w-xs">
              <p className="text-lg font-semibold text-center md:text-right dark:text-primary-100">
                Clients
              </p>
              <p className="text-center md:text-right dark:text-primary-300">
                Our clients' industry expertise, customer insights, and business
                goals are crucial to creating the best solution. We value their
                input and work closely with them to achieve success.
              </p>
            </div>
            <span className="w-20 h-px bg-primary-700 dark:bg-primary-300 mt-12 md:mt-3.5 rotate-90 md:rotate-0"></span>
          </div>
          <div className="flex flex-col items-center mx-0 circle-box md:items-start md:flex-row md:-mx-16">
            <div className="w-40 h-40 mr-0 bg-gray-300 rounded-full md:-mr-16"></div>
            <div className="w-40 h-40 -mt-10 bg-red-400 rounded-full md:mt-20 opacity-40"></div>
          </div>
          <div className="flex flex-col md:flex-row items-center md:items-start gap-x-3.5 mt-5 md:mt-28 z-10">
            <span className="w-20 h-px bg-primary-700 dark:bg-primary-300 mt-0 md:mt-3.5 rotate-90 md:rotate-0 mb-10 md:mb-0"></span>
            <div className="w-64 mx-auto text-left md:w-56 md:mx-0 lg:w-64 xl:max-w-xs">
              <p className="text-lg font-semibold text-center md:text-left dark:text-primary-100">
                Easesol
              </p>
              <p className="text-center md:text-left dark:text-primary-300">
                Our diverse team brings a wealth of technical skills and
                expertise to every project. Our guidance, experience, and
                attention to detail are evident in every solution we deliver.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LeadingByExample
